/* eslint-disable react/display-name */
import React from 'react';

import { Section } from 'src/components/molecules/Section';

export default function Drawer({ heading, callouts, customData }) {
  customData = JSON.parse(customData);
  return (
    <Section {...customData?.flags}>
      <h2 style={{ textAlign: 'center' }}>image below</h2>
    </Section>
  );
}
